// @ts-nocheck
import React, { useState } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { injectIntl } from 'gatsby-plugin-intl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'animate.css/animate.min.css';
import { Row, Form, Col } from 'react-bootstrap';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Phone from '../components/Phone';
import Title from '../components/Title';
import SubmitButton from '../components/SubmitButton';
import heroImage from '../images/banner-3.jpg';
import Footer from '../components/Footer';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h1`
  color: #004165;
  font-size: ${({ size }) => size ? `${size}em` : '2em'};
  font-weight: bold;
  margin-bottom: 1em;
`;

const KiteBooking = () => {
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    kaplanTests: '',
    _gotcha: '',
    _replyto: ''
  });

  const handleChange = event => {
    const { name } = event.target;
    const { value } = event.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const handleChangePhone = phone => {
    setFormValues({ ...formValues, phone });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post('https://formspree.io/f/xvoddkwd', {
        ...formValues,
        kiteTermsAndConditions: true,
        termsAndConditionsAccepted: true,
        privacyPolicyAccepted: true,
        _cc: 'info@homestaynetwork.org',
        _subject: 'New Kaplan KITE Test Booking'
      })
      .then(() => {
        setFormValues({
          firstName: '',
          lastName: '',
          phone: '',
          kaplanTests: '',
          _gotcha: '',
          _replyto: ''
        });
        toast.success('Success! Your enquiry was submitted successfully!');
      });
  };

  return (
    <>
      <Helmet />
      <Layout>
        <SEO title="Book a KITE test" />
        <Wrapper className="container-fluid">
          <Title imageUrl={heroImage} position="top" text="Book a KITE test" centered />
          <Row className="pt-5">
            <Col lg={5} md={8} sm={12} className="mx-auto">
              <Heading>
                Complete the form below to book a KITE test.
              </Heading>
              <Heading as="h2" size={1.5}>
                We will email you with further information about payment options and the how to access the testing platform.
              </Heading>
              <p>All fields marked with an asterisk (*) are mandatory.</p>
              <Form onSubmit={(e) => handleSubmit(e)}>
                <Form.Control
                  type="text"
                  name="_gotcha"
                  style={{ display: 'none' }}
                  value={formValues._gotcha}
                  onChange={handleChange}
                />
                <Form.Row>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control
                      id="firstName"
                      name="firstName"
                      onChange={handleChange}
                      value={formValues.firstName}
                      type="text"
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Last Name *</Form.Label>
                    <Form.Control
                      id="lastName"
                      name="lastName"
                      onChange={handleChange}
                      value={formValues.lastName}
                      type="text"
                      required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                      id="contactEmail"
                      name="_replyto"
                      onChange={handleChange}
                      value={formValues._replyto}
                      type="email"
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Phone *</Form.Label>
                    <Phone
                      onChange={handleChangePhone}
                      value={formValues.phone}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0">How many Kaplan KITE Tests would you like to book?* *</Form.Label>
                    <p className="mb-2">
                      <small>The cost is $90 AUD per test.</small>
                    </p>
                    <Form.Control
                      id="kaplanTests"
                      name="kaplanTests"
                      as="select"
                      onChange={handleChange}
                      value={formValues.kaplanTests}
                      type="email"
                      required
                    >
                      {[1,2,3,4,5,6].map(option => (
                        <option value={option}>{option}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label={(
                      <>I agree with the <a href="https://www.takekite.com/documents/KITE%20Terms%20&%20Conditions.pdf">KITE Terms &amp; Conditions</a> which can also be found at <a href="https://www.takekite.com/">takekite.com</a>.</>
                    )}
                    required
                  />
                  <Form.Check
                    type="checkbox"
                    label={(
                      <>I have read and agree with the Virtual Homestay ENGLISH <a href="/en/terms-and-conditions">Terms & Conditions</a> which can be found at the footer of the page. *</>
                    )}
                    required
                  />
                  <Form.Check
                    type="checkbox"
                    label={(
                      <>I agree with the MyStay English <a href="https://mystayenglish.com/en/privacy-policy">privacy policy</a> which can be found at the footer of the page. *</>
                    )}
                    required
                  />
                </Form.Group>
                <SubmitButton variant="primary" type="submit">
                  Submit
                </SubmitButton>
              </Form>
            </Col>
          </Row>
          <Footer />
        </Wrapper>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default injectIntl(KiteBooking);
